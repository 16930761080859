.paddingHorizontal {
    padding: 20px 90px;
}

.box-title {
    font-size: 20px;
    font-weight: 700;
    height: 23px;
    padding: 0 18px 0 0;
}

.header .box-title {
    font-size: 35px;
    padding: 0 10px;
}
